import {
  Box,
  Container,
  CssBaseline,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Header from "../../header";
import * as React from "react";
import pic1 from "../../images/Buergerstiftung_klein01.jpg";
import pic2 from "../../images/Buergerstiftung_klein02.jpg";
import pic3 from "../../images/Buergerstiftung_klein03.jpg";
import { theme } from "../../Theme";

export default function AboutUs() {
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <CssBaseline />
        <Header name="Über uns" />
        <Box paddingTop={5} fullwidth={true}>
          <Typography variant="h6" style={{ textTransform: "uppercase" }}>
            Bürgerstiftung Berlin
          </Typography>
          <Typography variant="body2">
            <div>
              <blockquote>
                <p>
                  <strong>
                    &quot;Es gilt, die Menschen in dieser Stadt davon zu
                    überzeugen, dass das Geld, das sie der Bürgerstiftung
                    stiften, für die Zukunft Berlins gewinnbringend angelegt
                    ist.&quot;
                  </strong>
                  <br />
                  <em>
                    Richard von Weizsäcker (1920-2015) Ehem. Bundespräsident,
                    Bürgermeister Berlins und Kuratoriumsmitglied der
                    Bürgerstiftung Berlin
                  </em>
                </p>
              </blockquote>
            </div>
          </Typography>
          <Typography variant="body2">
            Mit verschiedenen Projekten und 500 Ehrenamtlichen unterstützt die
            Bürgerstiftung Berlin Kin­der, Jugendliche, junge Eltern und ältere
            Menschen in Seniorenheimen. <br />
            Die Projekte werden alle selbst entwickelt und in intensiver
            Zusammenarbeit mit Schulen, Heimen, Gesundheitsämtern umgesetzt.
            Daraus entstehen engmaschige Unterstützungsnetzwerke, die für ein
            zuverlässig funktionierendes System effizienter und unbürokratischer
            Hilfe stehen. Da die Perspektive aller Projekte immer auf
            Langfristigkeit angelegt ist, gelingt es, auf den Erfahrungen Vieler
            aufzubauen und herausfordernde Situationen gut abzufedern. Da so die
            Projekte stetig genau an den Bedarfen ausgerichtet werden können,
            ist es auch möglich, diese zu Konzepten weiterzuentwickeln, die auch
            neuere wissenschaftlich basierte Ansätze in bestehende Strukturen
            implementieren können.
          </Typography>
          <Box
            sx={{
              marginTop: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={pic1} alt={""} width="100%" />
          </Box>
          <Typography variant="body2" marginTop={4}>
            Die Qualität der Projektarbeit hat sich vor allem in der Schul- und
            Kitalandschaft herumgesprochen – inzwischen profitieren über 10.000
            Kinder in 140 Schulen und Kitas von unserem Engagement – und die
            Nachfrage übersteigt in allen Projekten unsere derzeitigen
            Kapazitäten. Deswegen sammeln wir beim diesjährigen Art-Dinner
            Spenden für alle Projekte, insbesondere auch für die Aufstockung
            unserer personellen Kapazitäten, um neue Projektstandorte einrichten
            und betreuen zu können.
          </Typography>
          <Box
            sx={{
              marginTop: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={pic2} alt={""} width="100%" />
          </Box>
          <Typography variant="body2" marginTop={4}>
            Die Finanzierung der Projekte der Bürgerstiftung Berlin basieren
            hauptsächlich auf Spenden und Fördergeldern, die unmittelbar den
            Projekten zufließen. Daher möchten wir Sie mit dem Art Dinner
            einladen, unsere Projekte in Berlin mit einer Spende oder der
            aktiven Beteiligung an unserer Auktion zu unterstützen.
          </Typography>
          <Box
            sx={{
              marginTop: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={pic3} alt={""} width="100%" />
          </Box>
          <Typography variant="body2" marginTop={4}>
            <p>
              Wenn Sie uns nachhaltig unterstützen wollen, informieren wir Sie
              gerne über die Möglichkeiten von Treuhandstiftungen,
              Stiftungsfonds und Zustiftungen unter &nbsp;
              <a
                href="http://www.buergerstiftung-berlin.de"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.buergerstiftung-berlin.de
              </a>
              &nbsp; oder melden Sie sich gern direkt unter &nbsp;
              <span>
                <a href="mailto:mail@buergerstiftung-berlin.de">
                  mail@buergerstiftung-berlin.de
                </a>
              </span>
              &nbsp; und wir vereinbaren einen persönlichen Termin.
            </p>
          </Typography>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
