import React, { useEffect, useState } from "react";
import { Card, CardActionArea, CardContent, Fab } from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

export const ImageUpload2 = ({ id, src, onChange }) => {
  const [mainState, setMainState] = useState("initial");
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileRemoved, setFileRemoved] = useState(false);

  useEffect(() => {
    if (src && !fileRemoved) {
      setSelectedFile(src);
      setMainState("uploaded");
    }
  });
  const imageResetHandler = () => {
    console.log("reset");
    setFileRemoved(true);
    setMainState("initial");
    setSelectedFile(null);
    onChange(null);
  };
  const handleUploadClick = (event) => {
    setFileRemoved(false);
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function () {
      console.log([reader.result]);
      setSelectedFile([reader.result]);
    }.bind(this);

    setSelectedFile(event.target.files[0]);
    console.log(event.target.files[0]);
    setMainState("uploaded");
    onChange(event.target.files[0]);
  };

  return (
    <Card
      id={id}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
      }}
    >
      {mainState === "initial" ? (
        <CardContent>
          <input
            style={{ display: "none" }}
            accept="image/jpg"
            id={id + "contained-button-file"}
            multiple
            type="file"
            onChange={handleUploadClick}
          />
          <label htmlFor={id + "contained-button-file"}>
            <Fab component="span" color="#F1B434">
              <AddPhotoAlternateIcon style={{ zIndex: 0 }} />
            </Fab>
          </label>
        </CardContent>
      ) : mainState === "uploaded" ? (
        <CardActionArea onClick={imageResetHandler}>
          <img width="100%" src={selectedFile} alt={"image of " + id} />
        </CardActionArea>
      ) : (
        <></>
      )}
    </Card>
  );
};
