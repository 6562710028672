import React from "react";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { FormInputProps } from "./FormInputProps";
import { InputAdornment } from "@mui/material";

export const FormInputEuro = ({
  name,
  control,
  label,
  required,
  handleChange,
}: FormInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          helperText={error ? error.message : null}
          size="small"
          error={!!error}
          onChange={(event) => {
            handleChange(event);
            onChange(event);
          }}
          value={value}
          fullWidth
          label={label}
          variant="outlined"
          required={required}
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">€</InputAdornment>,
          }}
        />
      )}
    />
  );
};
