import dayjs, { Dayjs } from "dayjs";

export class ArtObjectReservation {
  constructor(
    id: string,
    userId: string,
    objectId: string,
    objectName: string,
    reservationDate: Dayjs,
    confirmationDate: Dayjs,
    reservationEmail: string,
    reservationUserName: string,
    fulfilled: boolean,
    amount: number
  ) {
    this.id = id;
    this.userId = userId;
    this.objectId = objectId;
    this.objectName = objectName;
    this.reservationDate = reservationDate;
    this.confirmationDate = confirmationDate;
    this.reservationEmail = reservationEmail;
    this.reservationUserName = reservationUserName;
    this.fulfilled = fulfilled;
    this.amount = amount;
  }
}

export const reservationConverter = {
  toFirestore: (object) => {
    return {
      id: object.id,
      userId: object.userId,
      objectId: object.objectId,
      objectName: object.objectName,
      reservationDate: object.reservationDate,
      confirmationDate: object.reservationDate,
      reservationEmail: object.reservationEmail,
      reservationUserName: object.reservationUserName,
      fulfilled: object.fulfilled,
      amount: object.amount,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new ArtObjectReservation(
      data.id,
      data.userId,
      data.objectId,
      data.objectName,
      data.reservationDate ? dayjs.unix(data.reservationDate.seconds) : null,
      data.confirmationDate ? dayjs.unix(data.confirmationDate.seconds) : null,
      data.reservationEmail,
      data.reservationUserName,
      data.fulfilled,
      data.amount
    );
  },
};
