import {
  Box,
  Container,
  CssBaseline,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Header from "../../header";
import * as React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { getAuth, updateEmail } from "firebase/auth";
import { Edit, Save } from "@mui/icons-material";
import { theme } from "../../Theme";

export default function UserProfileView() {
  const [emailEdit, setEmailEdit] = useState(false);
  const auth = getAuth();
  const [inputText, setInputText] = useState(auth.currentUser.email);
  const [errorText, setErrorText] = useState("");
  const [error, setError] = useState(false);

  const handleEmailEdit = () => {
    if (emailEdit) {
      setEmailEdit(false);
    } else {
      setEmailEdit(true);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!emailEdit) {
      setError(false);
      console.log("handle submit");
      console.log(inputText);
      updateEmail(auth.currentUser, inputText)
        .then(() => {
          console.log("success");
        })
        .catch((er) => {
          console.error(er);
          setError(true);
          if (er.code === "auth/email-already-in-use") {
            setErrorText("Email ist bereits registriert");
          } else if (er.code === "auth/invalid-email") {
            setErrorText("Angegebene Email ist nicht valide");
          } else {
            setErrorText("Fehler aufgetreten: " + er.message);
          }
        });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <CssBaseline />
        <Header name={"Mein Konto: " + auth.currentUser.displayName} />
        <Box sx={{ maxWidth: "100%", marginTop: "20px" }}>
          <Typography>Kontodaten:</Typography>
          <Box
            component="form"
            noValidate
            sx={{ mt: 2 }}
            onSubmit={handleSubmit}
          >
            <TextField
              disabled={!emailEdit}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Addresse"
              name="email"
              autoComplete="email"
              autoFocus
              error={error}
              helperText={error ? `${errorText}` : null}
              value={inputText}
              onChange={(e) => {
                setInputText(e.target.value);
              }}
              // startAdornment={
              //     <InputAdornment position="start">
              //         <AccountCircle/>
              //     </InputAdornment>
              // }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle email edit"
                      onClick={handleEmailEdit}
                      // onMouseDown={handleEmailEdit}
                      type="submit"
                      edge="end"
                    >
                      {emailEdit ? <Save /> : <Edit />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
          <Box sx={{ background: "#F1B434" }}>
            <nav>
              <List>
                <ListItem
                  disablePadding
                  component={Link}
                  to={`/user/gebote`}
                  style={{ color: "#000000" }}
                >
                  <ListItemButton>
                    <ListItemText primary="Meine Gebote und Käufe" />
                  </ListItemButton>
                </ListItem>
                <ListItem
                  disablePadding
                  component={Link}
                  to={`/user/notifications`}
                  style={{ color: "#000000" }}
                >
                  <ListItemButton>
                    <ListItemText primary="Benachrichtigungen" />
                  </ListItemButton>
                </ListItem>
              </List>
            </nav>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
