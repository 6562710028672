import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import * as React from "react";

export function LinkCard(name, link) {
  return (
    <Card
      style={{
        width: 140,
        height: 140,
        boxShadow:
          "12px 13px 10px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1)",
        background:
          "linear-gradient(104.39deg, #F1B434 -16.39%, #AC780A 175.09%)",
        backdropFilter: "blur(5px)",
        borderRadius: "20px",
      }}
    >
      <CardActionArea
        to={link}
        component={Link}
        style={{
          width: 140,
          height: 140,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CardContent>
          <Typography align="center" variant={"body1"} color="#FFFFFF">
            {name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
