import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Copyright from "../../common/Copyright";
import logo from "../../images/BST_Logo_25_Jahre_CMYK.jpg";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "@mui/material";
import { theme } from "../../Theme";
import queryString from "query-string";
import OneSignal from "react-onesignal";

export default function LoginView() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const authentication = getAuth();
    signInWithEmailAndPassword(
      authentication,
      data.get("email"),
      data.get("password")
    )
      .then((credential) => {
        sessionStorage.setItem(
          "Auth Token",
          credential._tokenResponse.refreshToken
        );
        console.log(credential);
        OneSignal.setExternalUserId(credential.user.uid)
          .then(console.log)
          .catch(console.error);
        const { redirectTo } = queryString.parse(location.search);
        navigate(redirectTo == null ? "/home" : redirectTo);
      })
      .catch((error) => {
        console.log(error);
        if (error.code === "auth/wrong-password") {
          toast.error("Falsches Passwort");
        }
        if (error.code === "auth/user-not-found") {
          toast.error("User nicht gefunden");
        }
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <ToastContainer />
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={logo} alt="Buergerstiftung Logo" height={100} />
          <Typography component="h1" variant="h5">
            25 Jahre Bürgerstiftung Berlin
          </Typography>
          <Typography component="h1" variant="h5" paddingTop={2}>
            Anmelden
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Addresse"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Passwort"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, borderRadius: 20 }}
            >
              Login
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/forgot" variant="body2">
                  Passwort vergessen?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/register" variant="body2">
                  {"Registrieren"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
