export class UserBidding {
  constructor(
    artObjectId,
    artObjectName,
    artObjectArtist,
    bidding,
    biddingDate,
    highestBid,
    highestBidder,
    imageUrl,
    still
  ) {
    this.artObjectId = artObjectId;
    this.artObjectName = artObjectName;
    this.artObjectArtist = artObjectArtist;
    this.bidding = bidding;
    this.biddingDate = biddingDate;
    this.highestBid = highestBidder;
    this.imageUrl = imageUrl;
    this.still = still;
  }

  toString() {
    return this.artObjectName;
  }
}

// Firestore data converter
export const userBiddingConverter = {
  toFirestore: (object) => {
    return {
      artObjectId: object.artObjectId,
      artObjectName: object.artObjectName,
      artObjectArtist: object.artObjectArtist,
      bidding: object.bidding,
      biddingDate: object.biddingDate,
      highestBid: object.highestBid,
      highestBidder: object.highestBidder,
      imageUrl: object.imageUrl,
      still: object.still,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new UserBidding(
      data.artObjectId,
      data.artObjectName,
      data.artObjectArtist,
      data.bidding,
      data.biddingDate,
      data.highestBid,
      data.highestBidder,
      data.imageUrl,
      data.still
    );
  },
};
