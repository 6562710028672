import {
  Box,
  Container,
  CssBaseline,
  Grid,
  ThemeProvider,
} from "@mui/material";
import Header from "../../header";
import { theme } from "../../Theme";
import * as React from "react";

export default function Donation() {
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <CssBaseline />
        <Header name="Spende" />
        <Container
          sx={{
            width: "100%",
            height: "100%",
            marginLeft: "8",
            marginRight: "8",
            padding: "0",
          }}
        >
          <Grid item xs={12} p={0}>
            <Box
              paddingTop={10}
              sx={{
                // position: "absolute",
                // top: "20px",
                left: "0",
                right: "0",
                bottom: "0",
              }}
            >
              <iframe
                style={{
                  overflow: "hidden",
                  display: "block",
                  width: "100%",
                  height: "800px",
                  border: "none",
                }}
                src="./twinkle.html"
                title="twinkle-donation"
              />
            </Box>
          </Grid>
        </Container>
      </Container>
    </ThemeProvider>
  );
}
