import { toast } from "react-toastify";
import {
  createUserWithEmailAndPassword,
  getAuth,
  updateProfile,
} from "firebase/auth";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import queryString from "query-string";
import OneSignal from "react-onesignal";

export function register_user(navigate, location) {
  return (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const firstName = data.get("firstName");
    const lastName = data.get("lastName");
    const email = data.get("email");
    const password = data.get("password1");
    const password2 = data.get("password2");

    const addressLine1 = data.get("address-line1");
    const addressLine2 = data.get("address-line2");
    const postalCode = data.get("postal-code");
    const addressLevel2 = data.get("address-level2");
    const gender = data.get("gender");

    if (password !== password2) {
      toast.error("Passwörter stimmen nicht überein");
      return;
    }

    const displayName = firstName + " " + lastName;
    // eslint-disable-next-line no-console
    let user = null;
    const authentication = getAuth();

    createUserWithEmailAndPassword(authentication, email, password)
      .then((response) => {
        console.log(response);
        sessionStorage.setItem(
          "Auth Token",
          response._tokenResponse.refreshToken
        );
      })
      .then(function () {
        user = authentication.currentUser;
        // user.sendEmailVerification();
      })
      .then(function () {
        authentication.languageCode = "de";
        return updateProfile(user, {
          displayName: displayName,
        });
      })
      .then(function () {
        const db = getFirestore();
        return setDoc(doc(db, "users", user.uid), {
          firstName: firstName,
          lastName: lastName,
          gender: gender,
          email: email,
          addressLine1: addressLine1,
          addressLine2: addressLine2,
          postalCode: postalCode,
          city: addressLevel2,
          phoneVerified: false,
          admin: false,
          chat: false,
        });
      })
      .then(function () {
        OneSignal.setExternalUserId(user.id)
          .then(console.log)
          .catch(console.error);
      })
      .then(function () {
        const { redirectTo } = queryString.parse(location.search);
        return navigate(redirectTo == null ? "/sms" : redirectTo);
      })
      .catch((error) => {
        console.log(error);
        if (error.code === "auth/email-already-in-use") {
          toast.error("Email ist bereits registriert");
        } else if (error.code === "auth/invalid-email") {
          toast.error("Angegebene Email ist nicht valide");
        } else if (error.code === "auth/weak-password") {
          toast.error("Passwort zu schwach (mindestens 6 Zeichen");
        } else {
          toast.error("Fehler aufgetreten: " + error.message);
        }
      });
  };
}
