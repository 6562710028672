import React, { useCallback, useEffect, useState } from "react";

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import OneSignal from "react-onesignal";

import { ToastContainer } from "react-toastify";
import { Box } from "@mui/material";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { theme } from "../../Theme";
import { de } from "date-fns/locale";
import NavBar from "../../NavBar";

export default function AuthApp() {
  const navigate = useNavigate();
  const authToken = sessionStorage.getItem("Auth Token");
  const auth = getAuth();
  const [userId, setUserId] = useState(auth.currentUser?.uid);
  const [validUser, setValidUser] = useState(false);

  onAuthStateChanged(auth, (user) => {
    if (user && user.uid && user.uid !== userId) {
      const emptyUserId = user?.uid != null ? user?.uid?.trim() === "" : false;
      if (user && user.uid && !emptyUserId) {
        setUserId(user.uid);
        console.log("user logged in");
        OneSignal.setExternalUserId(user.uid).then(console.log);
      } else {
        console.log("user logged out");
        setUserId(null);
      }
    }
  });

  useEffect(() => {
    if (userId) OneSignal.setExternalUserId(userId).catch(console.error);
  }, [userId]);

  const location = useLocation();
  const db = getFirestore();
  const checkUserToken = useCallback(async () => {
    if (userId) {
      const user = await getDoc(doc(db, "users", userId));
      const phoneVerified = user.data().phoneVerified;
      if (!phoneVerified) {
        navigate("/sms?redirectTo=" + location.pathname);
      }
      setValidUser(phoneVerified);
    }
  }, [db, location.pathname, navigate, userId]);

  useEffect(() => {
    const emptyUserId = userId != null ? userId.trim() === "" : false;
    console.log(`userId: ${userId}`);
    if (!authToken || !userId || emptyUserId) {
      if (
        !location.pathname.includes("register") &&
        !location.pathname.includes("login") &&
        !location.pathname.includes("forgot")
      ) {
        console.log("user not logged in, navigate to login");
        navigate("/login?redirectTo=" + location.pathname);
      }
    } else {
      console.log("user logged in: " + userId);
      checkUserToken().catch(console.error);
    }
  }, [authToken, userId, navigate, location, checkUserToken]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={de}>
      <ThemeProvider theme={theme}>
        <div>
          <ToastContainer />
          <main>
            <Box paddingBottom="60px">
              <Outlet />
            </Box>
          </main>
          {validUser ? <NavBar /> : <></>}
        </div>
      </ThemeProvider>
    </LocalizationProvider>
  );
}
