import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import logo from "../../images/BST_Logo_25_Jahre_CMYK.jpg";
import Copyright from "../../common/Copyright";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Divider,
  FormControl,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { register_user } from "./register_user";
import { theme } from "../../Theme";

export default function RegisterView() {
  const navigate = useNavigate();
  const location = useLocation();
  const [gender, setGender] = React.useState("");
  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = register_user(navigate, location);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <ToastContainer />
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={logo} alt="Buergerstiftung Logo" height={100} />
          <Typography component="h1" variant="h5">
            Registrieren
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            // onChange={validateForm}
            sx={{ mt: 1 }}
          >
            <FormGroup>
              <TextField
                margin="normal"
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="Vorname"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="lastName"
                label="Nachname"
                name="lastName"
                autoComplete="family-name"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
              />
              <FormControl margin="normal" fullWidth>
                <InputLabel required id="gender-label">
                  Geschlecht
                </InputLabel>
                <Select
                  required
                  name="gender"
                  labelId="gender-label"
                  id="gender"
                  onChange={handleGenderChange}
                  value={gender}
                  label="Geschlecht"
                >
                  <MenuItem value={"male"}>Männlich</MenuItem>
                  <MenuItem value={"female"}>Weiblich</MenuItem>
                  <MenuItem value={"diverse"}>Divers</MenuItem>
                </Select>
              </FormControl>
              <Divider />
              <TextField
                margin="normal"
                required
                fullWidth
                id="address-line1"
                label="Addresse"
                name="address-line1"
                autoComplete="address-line1"
              />
              <TextField
                margin="normal"
                fullWidth
                id="address-line2"
                label="Address-Zusatz"
                name="address-line2"
                autoComplete="address-line2"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="postal-code"
                label="Postleitzahl (PLZ)"
                name="postal-code"
                autoComplete="postal-code"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="address-level2"
                label="Stadt"
                name="address-level2"
                autoComplete="address-level2"
              />
              <Divider />
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel required htmlFor="password1">
                  Passwort
                </InputLabel>
                <OutlinedInput
                  required
                  name="password1"
                  label="Passwort"
                  type={values.showPassword ? "text" : "password"}
                  id="password1"
                  autoComplete="new-password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel required htmlFor="password1">
                  Passwort wiederholen
                </InputLabel>
                <OutlinedInput
                  required
                  name="password2"
                  label="Passwort wiederholen"
                  type={values.showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  id="password2"
                  autoComplete="new-password"
                />
              </FormControl>
              <Divider />
              <FormControlLabel
                control={
                  <Switch
                    value="acceptTerms"
                    required
                    id="acceptTerms"
                    color="primary"
                  />
                }
                label={
                  <div>
                    <span>Ich stimme den </span>
                    <Link
                      href="https://www.iubenda.com/nutzungsbedingungen/21204075"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      AGBs
                    </Link>
                    <span> und den </span>
                    <Link
                      href="https://www.iubenda.com/privacy-policy/21204075"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Datenschutz-Bestimmungen
                    </Link>
                  </div>
                }
              />
              <Button
                // disabled={!formValid}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, borderRadius: 20 }}
              >
                Registrieren
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link href="login" variant="body2">
                    Account schon vorhanden?
                  </Link>
                </Grid>
              </Grid>
            </FormGroup>
          </Box>
        </Box>
        <Copyright sx={{ mt: 1 }} />
      </Container>
    </ThemeProvider>
  );
}
