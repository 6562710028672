import { Timestamp } from "firebase/firestore";

export class Message {
  constructor(
    id: string,
    userId: string,
    timestamp: Timestamp,
    message: string,
    read: boolean
  ) {
    this.id = id;
    this.userId = userId;
    this.timestamp = timestamp;
    this.message = message;
    this.read = read;
  }
}

export const messageConverter = {
  toFirestore: (message) => {
    return {
      userId: message.userId,
      timestamp: message.timestamp,
      message: message.message,
      read: message.read,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new Message(
      snapshot.id,
      data.userId,
      data.timestamp,
      data.message,
      data.read
    );
  },
};
