import dayjs, { Dayjs } from "dayjs";

export class ConfigData {
  constructor(id: string, type: string, endingDate: Dayjs) {
    this.id = id;
    this.type = type;
    this.endingDate = endingDate;
  }
}

export const configConverter = {
  toFirestore: (object) => {
    return {
      id: object.id,
      type: object.type,
      endingDate: object.endingDate,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new ConfigData(
      data.id,
      data.type,
      data.endingDate ? dayjs.unix(data.endingDate.seconds) : null
    );
  },
};
