import { initializeApp } from "firebase/app";

export default function initFirebase() {
  // Initialize Firebase
  initializeApp({
    apiKey: "AIzaSyDfAylSFOfOouDmsmVTrhl9tOE0lOdcI0k",
    authDomain: "artdinnerberlinauction.firebaseapp.com",
    projectId: "artdinnerberlinauction",
    storageBucket: "artdinnerberlinauction.appspot.com",
    messagingSenderId: "31725641566",
    appId: "1:31725641566:web:b34b14e2019a04458339cf",
    measurementId: "G-CR2Y7Y1ZX8",
  });
}
