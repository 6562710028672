import * as React from "react";
import Container from "@mui/material/Container";
import Header from "../../header";
import CssBaseline from "@mui/material/CssBaseline";
import { Grid, ThemeProvider, Typography } from "@mui/material";
import { LinkCard } from "../../common/LinkCard";
import { theme } from "../../Theme";
import { PrimaryLinkCard } from "../../common/PrimaryLinkCard";

export default function HomeView() {
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <CssBaseline />
        <Header />
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={{ xs: 2, sm: 4 }}
          sx={{ padding: 4 }}
        >
          <Grid item xs={12}>
            <Typography align="center" variant="h6">
              25 Jahre Bürgerstiftung Berlin – unsere Kunstdrucke
            </Typography>
          </Grid>

          <Grid item align="center" xs={12}>
            {PrimaryLinkCard("Kunstdrucke", "/auktion/still")}
          </Grid>

          <Grid item align="center" xs={6}>
            {LinkCard("Über uns", "/about")}
          </Grid>

          <Grid item align="center" xs={6}>
            {LinkCard("Spende", "/donation")}
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
