import * as React from "react";
import Container from "@mui/material/Container";
import Header from "../../header";
import CssBaseline from "@mui/material/CssBaseline";
import { Grid, ThemeProvider, Typography } from "@mui/material";
import { LinkCard } from "../../common/LinkCard";
import { theme } from "../../Theme";

export default function AuctionSelect() {
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <CssBaseline />
        <Header />
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={{ xs: 2, sm: 4 }}
          sx={{ padding: 4 }}
        >
          <Grid item xs={12}>
            <Typography
              align="center"
              variant="h6"
              style={{ textTransform: "uppercase" }}
            >
              Auktion
            </Typography>
          </Grid>

          <Grid item align="center" xs={6}>
            {LinkCard("Live Auktion", "/auktion/live")}
          </Grid>

          <Grid item align="center" xs={6}>
            {LinkCard("Kunstdrucke", "/auktion/still")}
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
