import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Header from "../../header";
import * as React from "react";
import { useEffect, useState } from "react";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { artObjectConverter } from "../../data/art_object";
import { getAuth } from "firebase/auth";
import { Link } from "react-router-dom";
import { Breathing, Image } from "react-shimmer";
import { theme } from "../../Theme";
import OneSignal from "react-onesignal";

export default function UserNotificationsView() {
  const [objectsWatched, setObjectsWatched] = useState([]);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const auth = getAuth();
  const userId = auth.currentUser?.uid;
  const db = getFirestore();

  useEffect(() => {
    const fetchObjects = async () => {
      const data = (
        await getDocs(
          collection(db, "objects").withConverter(artObjectConverter)
        )
      ).docs.map((snap) => snap.data());
      const userObjectsWatched = (
        await getDocs(collection(db, "users", userId, "watching"))
      ).docs.map((snap) => snap.data().artObject);
      const watched = data.filter((object) =>
        userObjectsWatched.some((id) => id === object.id.toString())
      );
      setObjectsWatched(watched);
    };
    fetchObjects().catch(console.error);
  }, [db, userId]);

  const handleNotificationActivate = () => {
    OneSignal.showNativePrompt().then(() => {
      console.log("showed prompt");
    });
    OneSignal.setSubscription(true).then(() => {
      console.log("activate subscription");
    });
  };

  const handleNotificationDisableClick = () => {
    setConfirmOpen(true);
  };

  const handleNotificationDisable = () => {
    OneSignal.setSubscription(false).then(() => {
      console.log("disabled subscription");
    });
    setConfirmOpen(false);
  };

  const handleClose = () => {
    setConfirmOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Header name={"Meine Benachrichtigungen"} />
        <CssBaseline />
        <Box paddingTop={5}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>
                Wenn Sie auf diesem Gerät Push-Notifications bekommen wollen,
                aktivieren Sie diese hier. Diese Aktion muss für jedes Gerät
                seperat durchgeführt werden. Sie können dieses Gerät auch
                deaktivieren
              </Typography>
              <br />
              <Typography>
                Gleichzeitig mit Push-Notifications bekommen Sie auch eine
                E-Mail auf die von Ihnen angegebene Addresse.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={handleNotificationActivate}
                variant="contained"
                fullWidth
                style={{ borderRadius: 20, background: "#F1B434" }}
              >
                Aktivieren
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={handleNotificationDisableClick}
                variant="text"
                fullWidth
              >
                Deaktivieren
              </Button>
            </Grid>
            {objectsWatched &&
              objectsWatched.map((watched) => (
                <Grid item xs={12} key={watched.id}>
                  <Card variant="outlined">
                    <CardActionArea
                      to={`/objects/${watched.id}`}
                      component={Link}
                    >
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <div style={{ height: 100 }}>
                              <Image
                                NativeImgProps={{ height: 100 }}
                                src={watched.image1}
                                fallback={
                                  <Breathing width="100%" height="100%" />
                                }
                              />
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>{watched.artist} </Typography>
                            <Typography>{watched.name}</Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </Box>
        <Dialog
          open={confirmOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Push-Notifications deaktivieren?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Wollen Sie wirklich die Push-Notifications für dieses Gerät
              deaktiveren? Sie bekommen dann keine Benachrichtigungen für
              beobactete Kunstobjekte mehr oder wenn Sie überboten werden.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Nein</Button>
            <Button onClick={handleNotificationDisable} autoFocus>
              Ja
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </ThemeProvider>
  );
}
