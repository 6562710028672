import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  CssBaseline,
  List,
  ListItem,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Header from "../../header";
import * as React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { theme } from "../../Theme";

export default function More() {
  function getImpressum() {
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" fontWeight="fontWeightMedium">
            Impressum
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ background: "#F1B434" }}>
          <h4>Kontakt</h4>
          <p>
            Bürgerstiftung Berlin
            <br />
            (Amtsgericht Berlin-Charlottenburg 3416/558-II.2) <br />
            (Steuernummer: 27/643/040531) <br />
            Schillerstraße 59
            <br />
            10627 Berlin-Charlottenburg
          </p>
          <p>
            <span>
              <a href="mailto:mail@buergerstiftung-berlin.de">
                mail@buergerstiftung-berlin.de
              </a>
            </span>
            <br />
            Fon: 030 - 83 22 81 13
          </p>
          <h4>Vorstand</h4>
          <p>
            Andrea Grebe (Vorsitzende)
            <br />
            Frank Wiethoff (stellv. Vorsitzender)
            <br />
            Helena Stadler
            <br />
            Angelika Schilling
            <br />
            Constanze von Kettler
          </p>
          <h4>Haftungsausschluss</h4>
          <p>
            Wir übernehmen keinerlei Haftung für die Inhalte extern verknüpfter
            Seiten. Für die Inhalte solcher Seiten sind ausschließlich deren
            Betreiber verantwortlich.
          </p>
          <h4>Urheberrecht</h4>
          <p>
            Die Inhalte auf dieser und den übrigen Seiten (einschließlich der
            Texte, Bilder, Graphiken- und Animationsdateien) sowie die
            Gestaltung der Seiten unterliegen dem Urheberrecht der
            Bürgerstiftung Berlin bzw. den namentlich genannten Firmen. Die
            Verbreitung ist nur mit unserer schriftlichen Genehmigung zulässig.
          </p>
          <h4>Design</h4>
          <p>
            Ekaterina Didenko
            <br />
          </p>
          <h4>Umsetzung • Hosting</h4>
          <p>
            Lukas Baronyai
            <br />
          </p>
        </AccordionDetails>
      </Accordion>
    );
  }

  function getDatenschutz() {
    return (
      <>
        <a
          href="https://www.iubenda.com/privacy-policy/21204075"
          rel="noopener noreferrer"
          target="_blank"
          title="Datenschutzerklärung"
        >
          Datenschutzerklärung
        </a>
      </>
    );
  }

  function getAgbs() {
    return (
      <a
        href="https://www.iubenda.com/nutzungsbedingungen/21204075"
        rel="noopener noreferrer"
        target="_blank"
        title="Nutzungsbedingungen"
      >
        Nutzungsbedingungen
      </a>
    );
  }

  function getCookies() {
    return (
      <a
        href="https://www.iubenda.com/privacy-policy/21204075/cookie-policy"
        rel="noopener noreferrer"
        target="_blank"
        title="Cookie-Richtlinie"
      >
        Cookie-Richtlinie
      </a>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <CssBaseline />
        <Header name={"Über die Website"} />
        <Box paddingTop={5}>
          {getImpressum()}
          <List>
            <ListItem>{getDatenschutz()}</ListItem>
            <ListItem>{getAgbs()}</ListItem>
            <ListItem>{getCookies()}</ListItem>
          </List>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
