import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import React from "react";
import { getAuth } from "firebase/auth";

export function AdminChatUserListView(props) {
  const auth = getAuth();

  const handleToggle = (userId, userName) => {
    props.setReceiverData({
      userId: userId,
      userName: userName,
    });

    props.navigate(`/admin/support/${userId}`);
  };

  return (
    <List
      dense
      sx={{
        width: "100%",
        // maxWidth: 360,
        bgcolor: "background.paper",
      }}
    >
      {/* eslint-disable-next-line no-unused-vars */}
      {props.users?.map((user, index) => {
        const labelId = `checkbox-list-secondary-label-${user.id}`;

        if (auth.currentUser?.id !== user.id) {
          return (
            <ListItem key={user.id} disablePadding>
              <ListItemButton
                onClick={() => {
                  handleToggle(user.id);
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    alt={`${user.username}`}
                    src={`${user.username}.jpg`}
                  />
                </ListItemAvatar>
                <ListItemText
                  id={labelId}
                  primary={`${user?.lastName ?? "User"}`}
                />
              </ListItemButton>
            </ListItem>
          );
        } else {
          return <></>;
        }
      })}
    </List>
  );
}
