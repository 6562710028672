import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import logo from "../../images/BST_Logo_25_Jahre_CMYK.jpg";
import Copyright from "../../common/Copyright";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert, FormGroup } from "@mui/material";
import {
  getAuth,
  linkWithPhoneNumber,
  RecaptchaVerifier,
  unlink,
} from "firebase/auth";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { theme } from "../../Theme";
import { BallTriangle } from "react-loader-spinner";
import queryString from "query-string";
import { FormInputPhone } from "../../common/FormInputPhone";
import { PhoneNumberUtil } from "google-libphonenumber";

export default function SmsView() {
  const navigate = useNavigate();
  const [isError, setIsError] = React.useState(false);
  const [isRequested, setIsRequested] = React.useState(false);
  const [requesting, setRequesting] = React.useState(false);
  const [error, setError] = React.useState("");
  const [phone, setPhone] = React.useState("");

  const auth = getAuth();
  const db = getFirestore();
  const location = useLocation();

  const confirmCode = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const code = data.get("code").toLowerCase().replaceAll(" ", "");

    window.confirmationResult
      .confirm(code)
      .then((result) => {
        // User signed in successfully.
        const userId = result.user.uid;
        setDoc(
          doc(db, "users", userId),
          {
            phoneVerified: true,
          },
          { merge: true }
        ).catch(console.error);
        setIsRequested(false);
        setRequesting(false);
        toast.info("SMS erfolgreich überprüft");
        const { redirectTo } = queryString.parse(location.search);
        console.log(redirectTo);
        return navigate(
          redirectTo == null ||
            redirectTo.includes("login") ||
            redirectTo.includes("register") ||
            redirectTo.includes("sms")
            ? "/home"
            : redirectTo
        );
      })
      .catch(() => {
        setIsError(true);
        setError("SMS Code nicht korrekt");
      });
  };

  const savePhone = async () => {
    const db = getFirestore();
    return setDoc(doc(db, "users", auth.currentUser.uid), {
      phone: phone,
    });
  };

  const requestCode = async (event) => {
    event.preventDefault();
    //const data = new FormData(event.currentTarget);
    //const phone = data.get("phone");
    console.log(phone);
    await savePhone(phone);

    const phoneUtil = PhoneNumberUtil.getInstance();
    const isValidPhoneNumber = phoneUtil.isValidNumber(
      phoneUtil.parseAndKeepRawInput(phone)
    );
    if (!isValidPhoneNumber) {
      toast.error("Ungültige Telefonnummer");
      return;
    }
    setRequesting(true);

    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      { size: "invisible" },
      auth
    );
    if (auth.currentUser === null) {
      toast.error("Unbekannter User");
      return;
    }
    return linkWithPhoneNumber(
      auth.currentUser,
      phone,
      window.recaptchaVerifier
    )
      .then(function (confirmationResult) {
        setIsRequested(true);
        window.confirmationResult = confirmationResult;
        setRequesting(false);
      })
      .catch(function (error) {
        console.log("error for sms confirmation");
        console.error(error);
        toast.error(error);
        if (error.code === "auth/provider-already-linked") {
          unlink(auth.currentUser, "phone")
            .then(() => {
              console.log("unlinked");
            })
            .catch((error) => {
              console.error(error);
              setRequesting(false);
              setIsRequested(false);
            });
        }
        setRequesting(false);
        setIsRequested(false);
      });
  };

  const handleSubmit = async (event) => {
    if (isRequested) {
      return await confirmCode(event);
    } else {
      return await requestCode(event);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <ToastContainer />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={logo} alt="Buergerstiftung Logo" height={100} />
          <Typography component="h1" variant="h5">
            Nur noch ein Schritt!
          </Typography>
          <Typography>
            Bitte geben Sie Ihre Handynummer ein und bestätigen Sie diese mit
            dem Code, den Sie per SMS bekommen.
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <FormGroup>
              <FormInputPhone
                margin="normal"
                required
                fullWidth
                id="phone"
                label="Telefon"
                name="phone"
                autoComplete="phone"
                placeholder="+496601234567"
                disabled={isRequested}
                autoFocus
                onChange={setPhone}
              />
              {isRequested ? (
                <TextField
                  margin="normal"
                  name="code"
                  required
                  fullWidth
                  id="code"
                  label="SMS Code"
                  placeholder="123456"
                />
              ) : (
                <></>
              )}
              <div id="recaptcha-container"></div>
              {requesting ? (
                <BallTriangle
                  fullWidth
                  color="#00BFFF"
                  height={80}
                  width={80}
                  sx={{ mt: 3, mb: 2 }}
                />
              ) : (
                <></>
              )}
              {isRequested && !requesting ? (
                <div>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, borderRadius: 20 }}
                  >
                    Code überprüfen
                  </Button>
                  <Button fullWidth onClick={() => {}} variant="text">
                    Neustarten
                  </Button>
                </div>
              ) : (
                <></>
              )}
              {!isRequested && !requesting ? (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, borderRadius: 20 }}
                >
                  Code anfordern
                </Button>
              ) : (
                <></>
              )}
              {isError ? <Alert severity="error">{error}</Alert> : <></>}
            </FormGroup>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
