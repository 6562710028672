import React from "react";
import { Controller } from "react-hook-form";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { FormInputProps } from "./FormInputProps";

const options = [
  {
    label: "Live",
    value: false,
  },
  {
    label: "Still",
    value: true,
  },
];

export const FormInputRadio: React.FC<FormInputProps> = ({
  name,
  control,
  label,
}) => {
  const generateRadioOptions = () => {
    return options.map((singleOption) => (
      <FormControlLabel
        key={singleOption.value}
        value={singleOption.value}
        label={singleOption.label}
        control={<Radio />}
      />
    ));
  };

  return (
    <Controller
      name={name}
      defaultValue={true}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl>
          <FormLabel id="radio-label">{label}</FormLabel>
          <RadioGroup row value={value} onChange={onChange}>
            {generateRadioOptions()}
          </RadioGroup>
        </FormControl>
      )}
    />
  );
};
