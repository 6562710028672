import OneSignal from "react-onesignal";

export default async function initOneSignal() {
  await OneSignal.init({
    allowLocalhostAsSecureOrigin: process.env.NODE_ENV === "development",
    appId: "b9aa6830-97e4-4c41-bf74-71d7bd109580",
    safari_web_id: "web.onesignal.auto.4efa60d1-07b1-4ba2-89e8-693e25ede623",
    promptOptions: {
      enabled: true,
      actionMessage: "Möchten Sie auf diesem Gerät Benachrichtungen erhalten?",
      acceptButtonText: "Ja, bitte",
      cancelButtonText: "Nein, danke",
    },
    welcomeNotification: {
      title: "Art Dinner Berlin",
      message: "Willkommen!",
    },
  });
}
