import { useCallback, useEffect, useState } from "react";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import { User, userConverter } from "../../data/user";
import { BallTriangle } from "react-loader-spinner";
import {
  Container,
  CssBaseline,
  Grid,
  IconButton,
  ThemeProvider,
} from "@mui/material";
import { theme } from "../../Theme";
import Header from "../../header";
import DataTable from "react-data-table-component";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

export default function AdminUsersView() {
  const [data, setData] = useState([]);
  const [background, setBackground] = useState(false);
  const db = getFirestore();

  const fetchUsers = useCallback(async () => {
    const snapshot = await getDocs(
      collection(db, "users").withConverter(userConverter)
    );
    const data = snapshot.docs
      .map((doc) => doc.data())
      .sort((o1, o2) => (o1.id > o2.id ? 1 : -1));
    setData(data);
  }, [db]);

  const handleVerifyClick = useCallback(
    async (user: User) => {
      setBackground(true);
      setDoc(
        doc(db, "users", user.id),
        {
          phoneVerified: true,
          disabled: false,
        },
        { merge: true }
      ).catch(console.error);
      await fetchUsers();
      setBackground(false);
    },
    [db, fetchUsers]
  );

  const handleDisableClick = useCallback(
    async (user: User) => {
      console.log(user);
      setBackground(true);
      setDoc(
        doc(db, "users", user.id),
        {
          phoneVerified: false,
          disabled: true,
        },
        { merge: true }
      ).catch(console.error);
      await fetchUsers();
      setBackground(false);
    },
    [db, fetchUsers]
  );

  useEffect(() => {
    fetchUsers().catch(console.error);
  }, [background, handleVerifyClick, handleDisableClick, fetchUsers]);

  if (data.length === 0) {
    return (
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <BallTriangle color="#00BFFF" height={80} width={80} />
      </div>
    );
  }

  function getPhoneIcon(user) {
    if (user.phoneVerified === true) {
      return <CheckIcon />;
    } else if (user.disabled === true) {
      return <CloseIcon />;
    } else {
      return <></>;
    }
  }

  function getAdminIcon(user) {
    if (user.admin === true) {
      return <CheckIcon />;
    } else {
      return <></>;
    }
  }

  function getChatIcon(user) {
    if (user.chat === true) {
      return <CheckIcon />;
    } else {
      return <></>;
    }
  }

  const columns = [
    {
      name: "Vorname",
      selector: (user) => user.firstName,
      reorder: true,
      sortable: true,
      wrap: true,
    },
    {
      name: "Nachname",
      selector: (user) => user.lastName,
      reorder: true,
      sortable: true,
      wrap: true,
    },
    {
      name: "Email",
      selector: (user) => user.email,
      reorder: true,
      sortable: true,
      wrap: true,
      width: "15em",
    },
    {
      name: "SMS",
      // selector: user => user.phoneVerified ? CheckIcon() : "",
      cell: (user) => getPhoneIcon(user),
      reorder: true,
      sortable: true,
      width: "8em",
    },
    {
      name: "Admin",
      // selector: user => user.admin ? CheckIcon() : "",
      cell: (user) => getAdminIcon(user),
      reorder: true,
      sortable: true,
      width: "8em",
    },
    {
      name: "Chat",
      // selector: user => user.chat ? CheckIcon() : "",
      cell: (user) => getChatIcon(user),
      reorder: true,
      sortable: true,
      width: "8em",
    },
    {
      name: "Action",
      width: "8em",
      cell: (user) => (
        <>
          <IconButton
            aria-label="verify"
            color="primary"
            onClick={() => handleVerifyClick(user)}
          >
            <CheckIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            color="warning"
            onClick={() => handleDisableClick(user)}
          >
            <CloseIcon />
          </IconButton>
        </>
      ),
    },
  ];
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <CssBaseline />
        <Header name={"User Übersicht"} />
        <Grid
          container
          spacing={2}
          sx={{
            paddingTop: 4,
            paddingBottom: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid item>
            <DataTable
              columns={columns}
              data={data}
              highlightOnHover
              pagination
              pointerOnHover
              responsive
              striped
            />
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
