import { useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  ThemeProvider,
} from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../header";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import { BallTriangle } from "react-loader-spinner";
import { ArtObject, artObjectConverter } from "../../data/art_object";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { FormErrors } from "../../common/FormErrors";
import {
  postBiddingToFirestore,
  postReservationToFirestore,
} from "./post_to_firestore";
import parse from "html-react-parser";
import { theme } from "../../Theme";
import dayjs from "dayjs";
import { reservationConverter } from "../../data/art_object_reservation";
import { configConverter, ConfigData } from "../../data/config_data";
import TextField from "@mui/material/TextField";

export default function ObjectDetail() {
  const [object, setObject] = useState(new ArtObject());
  const [config, setConfig] = useState(new ConfigData());
  const [dataLoaded, setDataLoaded] = useState(false);
  const [amount, setAmount] = useState(1);
  const [userHasBidForObject, setUserHasBidForObject] = useState(false);
  const [userReservedObject, setUserReservedObject] = useState(false);
  const [userBoughtObject, setUserBoughtObject] = useState(false);
  const auth = getAuth();
  const db = getFirestore();
  let params = useParams();
  const objectId = params.objectId;
  const [biddingAllowed, setBiddingAllowed] = useState(false);
  const [objectAvailable, setObjectAvailable] = useState(true);

  const [biddingOpen, setBiddingOpen] = useState(false);
  const [reservationOpen, setReservationOpen] = useState(false);
  const [formErrors, setErrors] = useState({ bidding: "" });
  const [biddingValid, setBiddingValid] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userEmail, setUserEmail] = useState();
  const [userName, setUserName] = useState();

  const navigate = useNavigate();

  onAuthStateChanged(auth, (user) => {
    if (user && user.uid && user.uid !== userId) {
      console.log("user state changed");
      if (user.uid) {
        console.log("new user: " + user.uid);
        setUserId(user.uid);
        setUserEmail(user.email);
        setUserName(user.displayName);
      } else {
        console.log("set userId null");
        setUserId(null);
        setUserEmail(null);
        setUserName(null);
      }
    }
  });

  const handleCloseBidding = () => {
    setBiddingOpen(false);
  };

  const handleCloseReservation = () => {
    setReservationOpen(false);
  };

  const handleClickOpenBidding = () => {
    setBiddingOpen(true);
  };

  const handleClickOpenReservation = () => {
    setReservationOpen(true);
  };

  const handleBiddingFinishDate = (date) => {
    const now = dayjs();
    const isValid = now.isBefore(date);
    setBiddingAllowed(isValid);
  };

  const loadReservation = async (resId) => {
    const resRef = doc(db, "reservations", resId).withConverter(
      reservationConverter
    );
    const resSnap = await getDoc(resRef);
    return resSnap.data();
  };

  useEffect(() => {
    const fetchConfig = async (object) => {
      const snapshot = await getDocs(
        collection(db, "config").withConverter(configConverter)
      );

      const data = snapshot.docs.map((doc) => doc.data());
      let config;
      if (object.still) {
        config = data.find((config) => config.type === "still");
      } else {
        config = data.find((config) => config.type === "live");
      }
      setConfig(config);
      handleBiddingFinishDate(config.endingDate);
    };

    const fetchObject = async () => {
      const docRef = doc(db, "objects", params.objectId).withConverter(
        artObjectConverter
      );
      const docSnap = await getDoc(docRef);
      const fetchedObject = docSnap.data();

      setObject(fetchedObject);
      setObjectAvailable(object.amountAvailable > 0);
      setDataLoaded(true);
    };

    fetchObject().catch(console.error);
    fetchConfig(object).catch(console.error);

    const fetchReservations = async () => {
      let userReserved = false;
      let userBought = false;
      let i;
      let resId;

      if (object.reservationList) {
        for (i = 0; i < object.reservationList.length; i++) {
          resId = object.reservationList[i];
          const reservation = await loadReservation(resId);
          console.log(
            "eval userReserved: " +
              (userReserved || reservation.userId === userId)
          );
          userReserved = userReserved || reservation.userId === userId;
        }
      }

      if (object.buyerList) {
        for (i = 0; i < object.buyerList.length; i++) {
          resId = object.buyerList[i];
          const reservation = await loadReservation(resId);
          console.log(
            "eval userBought: " + (userBought || reservation.userId === userId)
          );
          userBought = userBought || reservation.userId === userId;
        }
      }

      console.log("reserved: " + userReserved);
      console.log("bought: " + userBought);
      setUserReservedObject(userReserved);
      setUserBoughtObject(userBought);
    };

    if (userId) {
      const userBidForObject = async () => {
        const userBiddings = (
          await getDocs(collection(db, "users", userId, "biddings"))
        ).docs.map((bid) => bid.data());
        const biddings = userBiddings
          .filter((bid) => bid.artObjectId.toString() === objectId)
          .map((bid) => bid.bidding);
        const hasBid = biddings.length > 0;
        setUserHasBidForObject(hasBid);
      };
      userBidForObject().catch(console.error);
      fetchReservations().catch(console.error);
    }
  }, [dataLoaded, db, objectId, params.objectId, userId]);

  const formatDate = (date) => {
    return date ? date.format("DD.MM.YYYY HH:mm") : "";
  };

  const onBiddingInputChange = (event) => {
    let valid;
    let minBid;
    let value = parseInt(event.target.value, 10);
    if (object.highestBidding && object.still) {
      minBid = parseInt(object.highestBidding, 10);
    } else {
      minBid = parseInt(object.startingPrice, 10);
    }
    valid = value > minBid;
    setBiddingValid(valid);
    if (!valid)
      setErrors({ bidding: "Gebot muss mindestens " + minBid + "€ betragen." });
    else setErrors({ bidding: "" });
    return valid;
  };

  const handleReservationSubmit = async (event) => {
    event.preventDefault();
    if (amount > object.amountAvailable || amount < 1) {
      toast.error(
        "Anzahl nicht möglich zu reservieren. " +
          (object.amountAvailable > 1
            ? "Bitte wählen Sie eine Anzahl zwischen 1 und " +
              object.amountAvailable +
              "."
            : "Nur 1 Objekt verfügbar.")
      );
    } else if (userId) {
      await postReservationToFirestore(
        db,
        objectId,
        object.name,
        userId,
        userEmail,
        userName,
        amount,
        setDataLoaded
      ).catch(console.error);
      toast.info(
        "Die Reservierung wurde verarbeitet. " +
          "Informationen zum Abschluss des Kaufes bekommen Sie per Mail."
      );
    }
  };

  const handleBiddingSubmit = async (event) => {
    if (userId) {
      event.preventDefault();
      const data = new FormData(event.target);
      const bidding = data.get("bidding");
      await postBiddingToFirestore(
        db,
        objectId,
        userId,
        userEmail,
        userName,
        setDataLoaded,
        bidding
      ).catch(console.error);
      toast.info(
        "Das Gebot von €" +
          bidding +
          " wurde verarbeitet. " +
          "Die Details finden Sie in Ihrem Konto. "
      );
    }
  };

  const currencyFormat = (num) => {
    return (
      "€" +
      parseInt(num, 10)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  };

  function renderCaption() {
    return (
      <>
        {object.artist ? object.artist + ": " : ""}
        {object.name}
        {object.type ? " - " + object.type : ""}
        {object.year ? ", " + object.year : ""}
      </>
    );
  }

  if (!dataLoaded)
    return (
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <BallTriangle color="#00BFFF" height={80} width={80} />
      </div>
    );

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <CssBaseline />
        <Header name={object.name} />
        <Grid
          container
          spacing={2}
          sx={{
            paddingTop: 4,
            paddingBottom: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid item p={2} xs={12} sm={8}>
            <Grid
              container
              spacing={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={6} m={"auto"}>
                <img
                  src={object.image1}
                  style={{ width: "100%", height: "auto" }}
                  alt={object.name}
                />
                {object.image1Copyright ? (
                  <Typography variant="caption">
                    {object.image1Copyright}
                  </Typography>
                ) : (
                  <></>
                )}
              </Grid>
              {object.image2 ? (
                <Grid item xs={6} m={"auto"}>
                  <img
                    src={object.image2}
                    style={{ width: "100%", height: "auto" }}
                    alt={object.name}
                  />
                  {object.image2Copyright ? (
                    <Typography variant="caption">
                      {object.image2Copyright}
                    </Typography>
                  ) : (
                    <></>
                  )}
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
            <Typography
              align="center"
              variant="subtitle1"
              gutterBottom
              component="div"
              style={{ fontStyle: "italic" }}
            >
              {renderCaption()}
            </Typography>

            <Typography
              variant="body1"
              gutterBottom
              mt={3}
              style={{ fontWeight: "bold" }}
            >
              Über den Artikel:
            </Typography>

            <Typography
              variant="h4"
              gutterBottom
              mt={3}
              style={{ fontWeight: "bold" }}
            >
              {object.name}
            </Typography>

            <Typography variant="body1" gutterBottom mt={3}>
              {parse(object.info)}
            </Typography>

            {object.logo ? (
              <Box
                fullwidth="true"
                mt={3}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Box m={"auto"}>
                  <img
                    src={object.logo}
                    style={{ width: "auto", height: "50px" }}
                    alt={object.artist}
                  />
                </Box>
              </Box>
            ) : (
              <></>
            )}

            {object.still ? (
              <Typography
                variant="body1"
                gutterBottom
                style={{ fontWeight: "bold" }}
              >
                Kaufpreis: {currencyFormat(object.startingPrice)}
              </Typography>
            ) : (
              <Typography
                variant="body1"
                gutterBottom
                mt={3}
                style={{ fontWeight: "bold" }}
              >
                Startgebot: {currencyFormat(object.startingPrice)}
              </Typography>
            )}

            {object.still && userBoughtObject ? (
              <Alert severity="success">
                Sie haben dieses Objekt erfolgreich erstanden!
              </Alert>
            ) : (
              <></>
            )}

            {object.still && userReservedObject && !userBoughtObject ? (
              <Alert severity="success">
                Sie haben dieses Objekt reserviert. Informationen zum Abschluss
                des Kaufes bekommen Sie per Mail.
              </Alert>
            ) : (
              <></>
            )}

            {object.still &&
            !objectAvailable &&
            !userBoughtObject &&
            !userReservedObject ? (
              <Alert severity="info">
                Dieses Objekt ist nicht mehr verfügbar.
              </Alert>
            ) : (
              <></>
            )}

            {!object.still && userHasBidForObject ? (
              <Alert severity="success">
                Sie bieten bereits für dieses Objekt. Die Details finden Sie in
                Ihrem Auktions-Konto. Sie erhalten am Ende der Vorauktion eine
                Mail, falls Sie das höchste Gebot abgegeben haben.
              </Alert>
            ) : (
              <></>
            )}

            <Typography variant="body1" gutterBottom>
              {object.still ? "Kauf bis:" : "Gebote bis:"}{" "}
              {formatDate(config.endingDate)}
            </Typography>
          </Grid>

          <Grid item p={2} xs={12} sm={4} width={"100%"}>
            <Grid container>
              <Grid item p={2} xs={4} sm={4}>
                {biddingAllowed &&
                userId &&
                object.still &&
                !userReservedObject &&
                !userBoughtObject &&
                objectAvailable ? (
                  <TextField
                    type="number"
                    size="small"
                    onChange={(event) => {
                      const newAmount = parseInt(event.target.value, 10);
                      if (newAmount > 0) {
                        setAmount(newAmount);
                      }
                    }}
                    InputProps={{
                      inputProps: {
                        min: 1,
                        max: object.amountAvailable,
                      },
                    }}
                    value={amount}
                    defaultValue={amount}
                    fullWidth
                    label={"Anzahl"}
                    variant="outlined"
                  />
                ) : (
                  <></>
                )}
              </Grid>

              <Grid item container p={2} xs={8} sm={8}>
                {!userId ? (
                  <>
                    <Grid item p={2} xs={12} sm={12}>
                      <Alert severity="info">Bitte Einloggen</Alert>
                    </Grid>
                    <Grid item p={2} xs={12} sm={12}>
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={() => {
                          navigate(`/login`);
                        }}
                        style={{ borderRadius: 20, background: "#F1B434" }}
                      >
                        Login/Registrierung
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}

                {biddingAllowed && userId && !object.still ? (
                  <Button
                    onClick={handleClickOpenBidding}
                    variant="contained"
                    fullWidth
                    style={{ borderRadius: 20, background: "#F1B434" }}
                  >
                    Bieten
                  </Button>
                ) : (
                  <></>
                )}

                {biddingAllowed &&
                userId &&
                object.still &&
                !userReservedObject &&
                !userBoughtObject &&
                objectAvailable ? (
                  <Button
                    onClick={handleClickOpenReservation}
                    variant="contained"
                    fullWidth
                    style={{ borderRadius: 20, background: "#F1B434" }}
                  >
                    Reservieren
                  </Button>
                ) : (
                  <></>
                )}
                {!biddingAllowed ? (
                  <Alert severity="info">Auktion bereits beendet</Alert>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Dialog
          open={reservationOpen}
          onClose={handleCloseReservation}
          onSubmit={handleReservationSubmit}
        >
          <DialogTitle>Reservierung bestätigen</DialogTitle>
          <form>
            <DialogContent>
              <DialogContentText>
                Sind Sie sicher, dass sie dieses Objekt reservieren wollen?
              </DialogContentText>
              <DialogContentText>
                Nach der Bestätigung erhalten Sie die Kaufinformationen per
                Mail.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseReservation}>Abbrechen</Button>
              <Button type="submit" onClick={handleCloseReservation}>
                Reservieren
              </Button>
            </DialogActions>
          </form>
        </Dialog>

        <Dialog
          open={biddingOpen}
          onClose={handleCloseBidding}
          onSubmit={handleBiddingSubmit}
        >
          <DialogTitle>Gebot abgeben</DialogTitle>
          <form>
            <DialogContent>
              <DialogContentText>Wieviel möchten Sie bieten?</DialogContentText>
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="bidding">Gebot abgeben</InputLabel>
                <OutlinedInput
                  onChange={onBiddingInputChange}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  id="bidding"
                  required
                  name="bidding"
                  startAdornment={
                    <InputAdornment position="start">€</InputAdornment>
                  }
                  label="Gebot abgeben"
                />
              </FormControl>
              <div className="panel panel-default">
                <FormErrors formErrors={formErrors} />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseBidding}>Abbrechen</Button>
              <Button
                type="submit"
                onClick={handleCloseBidding}
                disabled={!biddingValid}
              >
                Gebot abgeben
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </Container>
    </ThemeProvider>
  );
}
