export class User {
  constructor(
    id: string,
    addressLine1: string,
    addressLine2: string,
    admin: boolean,
    chat: boolean,
    city: string,
    email: string,
    firstName: string,
    gender: string,
    lastName: string,
    phoneVerified: boolean,
    postalCode: string,
    disabled: boolean
  ) {
    this.id = id;
    this.addressLine1 = addressLine1;
    this.addressLine2 = addressLine2;
    this.admin = admin;
    this.chat = admin;
    this.city = city;
    this.email = email;
    this.firstName = firstName;
    this.gender = gender;
    this.lastName = lastName;
    this.phoneVerified = phoneVerified;
    this.postalCode = postalCode;
    this.disabled = disabled;
  }

  toString() {
    return this.firstName + " " + this.lastName;
  }
}

export const userConverter = {
  toFirestore: (user) => {
    return {
      id: user.id,
      addressLine1: user.addressLine1,
      addressLine2: user.addressLine2,
      admin: user.admin,
      chat: user.chat,
      city: user.city,
      email: user.email,
      firstName: user.firstName,
      gender: user.gender,
      lastName: user.lastName,
      phoneVerified: user.phoneVerified,
      postalCode: user.postalCode,
      disabled: user.disabled,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new User(
      snapshot.id,
      data.addressLine1,
      data.addressLine2,
      data.admin,
      data.chat,
      data.city,
      data.email,
      data.firstName,
      data.gender,
      data.lastName,
      data.phoneVerified,
      data.postalCode,
      data.disabled
    );
  },
};
