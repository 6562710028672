import React, { useEffect, useState } from "react";

import { Outlet } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import NavBar from "../../NavBar";

import { ToastContainer } from "react-toastify";
import { Box } from "@mui/material";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { theme } from "../../Theme";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { de } from "date-fns/locale";
import { addResponseMessage, addUserMessage, Widget } from "react-chat-widget";
import "../../styles/chat.css";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { v4 as uuidv4 } from "uuid";
import { messageConverter } from "../../data/message";

const anonUid = uuidv4();
export default function UnauthApp() {
  const db = getFirestore();
  const auth = getAuth();

  const [userId, setUserId] = useState(null);
  const [messageIds] = useState([]);

  onAuthStateChanged(auth, (user) => {
    if (user && user.uid && user.uid !== userId) {
      if (user.uid) {
        console.log("user logged in");
        setUserId(user.uid);
      } else {
        console.log("user logged out");
        setUserId(anonUid);
      }
    }
  });

  useEffect(() => {
    if (userId != null) {
      console.log("support/" + userId + "/messages");
      return onSnapshot(
        query(
          collection(db, "support", userId, "messages").withConverter(
            messageConverter
          ),
          orderBy("timestamp")
        ),
        (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            const changedMessage = change.doc.data();
            console.log(changedMessage);
            if (!messageIds.includes(changedMessage.id)) {
              if (changedMessage.userId === userId) {
                addUserMessage(changedMessage.message);
              } else {
                addResponseMessage(changedMessage.message);
              }
              messageIds.push(changedMessage.id);
              if (!changedMessage.read) {
                markRead(changedMessage);
              }
            }
          });
        }
      );
    }
  }, [userId]);

  const markRead = async (readMessage) => {
    console.log("mark read: " + readMessage.id);
    await setDoc(
      doc(db, "support", userId, "messages", readMessage.id),
      {
        read: true,
      },
      { merge: true }
    );
  };

  const handleNewUserMessage = async (newMessage) => {
    const messages = (
      await getDocs(collection(db, "support", userId, "messages"))
    ).docs.map((snap) => snap.data());
    const nextMessageId = (messages.length ?? 0).toString();

    await setDoc(
      doc(db, "support", userId),
      {
        userId: userId,
      },
      { merge: true }
    ).catch(console.error);
    messageIds.push(nextMessageId);
    await setDoc(doc(db, "support", userId, "messages", nextMessageId), {
      userId: userId,
      timestamp: Timestamp.fromDate(new Date()),
      message: newMessage,
      read: false,
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={de}>
      <ThemeProvider theme={theme}>
        <div>
          <ToastContainer />
          <main>
            <Box paddingBottom="60px">
              <Outlet />
            </Box>
          </main>
          <Widget
            handleNewUserMessage={handleNewUserMessage}
            // emojis={false}
            title="Support Art Dinner Berlin"
            senderPlaceHolder="Nachricht eingeben..."
            subtitle="Nachricht hier eingeben"
          />
          <NavBar />
        </div>
      </ThemeProvider>
    </LocalizationProvider>
  );
}
