import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import HomeIcon from "@mui/icons-material/Home";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import TableChartIcon from "@mui/icons-material/TableChart";
import ChatIcon from "@mui/icons-material/Chat";
import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";

const MyBottomNavigationAction = styled(BottomNavigationAction)(`
  color: white;
  &.Mui-selected {
    color: #F1B434;
  }
`);

export default function NavBar() {
  const [value, setValue] = React.useState(0);
  const auth = getAuth();
  const db = getFirestore();
  const [userId, setUserId] = useState(null);
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  const [userIsChat, setUserIsChat] = useState(false);
  const authToken = sessionStorage.getItem("Auth Token");

  onAuthStateChanged(auth, (user) => {
    if (user && user.uid && user.uid !== userId) {
      if (user.uid) {
        console.log("user logged in");
        setUserId(user.uid);
      } else {
        console.log("user logged out");
        setUserId(null);
      }
    }
  });

  useEffect(() => {
    const checkUser = async () => {
      if (userId) {
        const user = await getDoc(doc(db, "users", userId));
        const isAdmin = user.data().admin === true;
        const isChat = user.data().chat === true;
        setUserIsAdmin(isAdmin && authToken != null);
        setUserIsChat(isChat && authToken != null);
      }
    };
    checkUser().catch(console.error);
  }, [authToken, db, userId]);

  useEffect(() => {
    const urlPage = window.location.pathname.substring(1).split("/")[0];
    const urlArrays = ["home", "drucke", "more", "user", "admin"];
    setValue(urlArrays.indexOf(urlPage));
  }, []);

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={value}
        style={{
          background: "#E87722",
          width: "100%",
          position: "fixed",
          bottom: 0,
        }}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <MyBottomNavigationAction
          component={Link}
          label="Home"
          icon={<HomeIcon />}
          to="/home"
        />
        <MyBottomNavigationAction
          component={Link}
          label="Kunstdrucke"
          icon={<LocalOfferIcon />}
          to="/auktion/still"
        />
        <MyBottomNavigationAction
          component={Link}
          label="Info"
          icon={<SearchIcon />}
          to="/more"
        />
        {userId ? (
          <MyBottomNavigationAction
            component={Link}
            label="Konto"
            icon={<AccountCircleIcon />}
            to="/user"
          />
        ) : null}
        {userIsChat ? (
          <MyBottomNavigationAction
            component={Link}
            label="Support"
            icon={<ChatIcon />}
            to={`/admin/support/${userId}`}
          />
        ) : null}
        {userIsAdmin ? (
          <MyBottomNavigationAction
            component={Link}
            label="Admin"
            icon={<TableChartIcon />}
            to="/admin"
          />
        ) : null}
      </BottomNavigation>
    </Paper>
  );
}
