import * as React from "react";
import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Header from "../../header";
import CssBaseline from "@mui/material/CssBaseline";
import { Button, Grid, ThemeProvider } from "@mui/material";
import { theme } from "../../Theme";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { artObjectConverter } from "../../data/art_object";
import { FormInputSelect } from "../../common/FormInputSelect";
import { useForm } from "react-hook-form";
import { BallTriangle } from "react-loader-spinner";
import { FormInputEuro } from "../../common/FormInputEuro";
import { FormInputText } from "../../common/FormInputText";
import Typography from "@mui/material/Typography";
import { FormErrors } from "../../common/FormErrors";
import { postBiddingToFirestore } from "../auction/post_to_firestore";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

export default function AdminManualBiddingView() {
  const db = getFirestore();

  const [objects, setObjects] = useState([]);
  const [selectedObject, setSelectedObject] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [biddingValid, setBiddingValid] = useState(false);
  const [formErrors, setErrors] = useState({ bidding: "" });

  const methods = useForm();
  const { handleSubmit, control } = methods;

  const fetchObjects = async () => {
    const db = getFirestore();
    const snapshot = await getDocs(
      collection(db, "objects").withConverter(artObjectConverter)
    );
    const data = snapshot.docs
      .map((doc) => doc.data())
      .filter((obj) => obj.still);
    setObjects(data);
  };

  useEffect(() => {
    fetchObjects().catch(console.error);
  }, []);

  const onSubmit = async (biddingData) => {
    setSubmitting(true);
    console.log(biddingData);
    const selectedObjectId = objects.find(
      (object) => object.id === biddingData.selectedObject
    ).id;
    const userId = uuidv4();

    await postBiddingToFirestore(
      db,
      selectedObjectId.toString(),
      userId,
      biddingData.email,
      biddingData.userName,
      setSubmitting,
      biddingData.bidding
    ).catch(console.error);

    toast.info("Das Gebot von €" + biddingData.bidding + " wurde verarbeitet");
    setSubmitting(false);
  };

  const onChangeObject = (event) => {
    const selectedId = event.target.value;
    const selected = objects.find((object) => object.id === selectedId);
    setSelectedObject(selected);
  };

  const currencyFormat = (num) => {
    return (
      "€" +
      parseInt(num, 10)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  };

  const onBiddingInputChange = (event) => {
    let valid;
    let minBid;
    let value = parseInt(event.target.value, 10);
    if (selectedObject.highestBidding && selectedObject.still) {
      minBid = parseInt(selectedObject.highestBidding, 10);
    } else {
      minBid = parseInt(selectedObject.startingPrice, 10);
    }
    valid = value > minBid;
    setBiddingValid(valid);
    if (!valid)
      setErrors({ bidding: "Gebot muss mindestens " + minBid + "€ betragen." });
    else setErrors({ bidding: "" });
    return valid;
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <CssBaseline />
        <Header name={"Manuelles Gebot"} />
        <Grid
          container
          spacing={2}
          sx={{
            paddingTop: 4,
            paddingBottom: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid item p={2} xs={12} sm={8} width={"100%"}>
            <Typography variant="h4">Gebot abgeben</Typography>
          </Grid>
          <Grid item p={2} xs={12} sm={8} width={"100%"}>
            <FormInputSelect
              required
              name="selectedObject"
              control={control}
              label="Kunstobjekt"
              handleChange={onChangeObject}
              options={objects
                .sort((a, b) => {
                  return a.id - b.id;
                })
                .map((object) => {
                  return {
                    id: object.id,
                    label: object.artist + ": " + object.name,
                  };
                })}
            />
          </Grid>

          <Grid item p={2} xs={12} sm={8} width={"100%"}>
            <FormInputText
              name="email"
              control={control}
              label="Email"
              required
            />
          </Grid>

          <Grid item p={2} xs={12} sm={8} width={"100%"}>
            <FormInputText
              name="userName"
              required
              control={control}
              label="User Name"
            />
          </Grid>

          <Grid item p={2} xs={12} sm={8} width={"100%"}>
            <FormInputEuro
              name="bidding"
              required
              control={control}
              label="Gebot"
              handleChange={onBiddingInputChange}
            />
          </Grid>

          {selectedObject != null &&
          selectedObject.still &&
          selectedObject.highestBidding !== undefined ? (
            <Typography
              variant="body1"
              gutterBottom
              style={{ fontWeight: "bold" }}
            >
              Aktuelles Gebot:{" "}
              {currencyFormat(
                selectedObject.highestBidding ?? selectedObject.startingPrice
              )}
            </Typography>
          ) : (
            <></>
          )}

          {selectedObject != null &&
          !(
            selectedObject.still && selectedObject.highestBidding !== undefined
          ) ? (
            <Typography
              variant="body1"
              gutterBottom
              mt={3}
              style={{ fontWeight: "bold" }}
            >
              Startgebot: {currencyFormat(selectedObject.startingPrice)}
            </Typography>
          ) : (
            <></>
          )}

          <div className="panel panel-default">
            <FormErrors formErrors={formErrors} />
          </div>

          <Grid item p={2} xs={12} sm={8} width={"100%"}>
            {submitting ? (
              <BallTriangle />
            ) : (
              <Button
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                fullWidth
                disabled={!biddingValid}
                style={{ borderRadius: 20, background: "#F1B434" }}
              >
                Submit
              </Button>
            )}
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
