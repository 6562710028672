import { useEffect, useState } from "react";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  setDoc,
  deleteDoc,
  getDoc,
  Timestamp,
} from "firebase/firestore";
import { reservationConverter } from "../../data/art_object_reservation";
import { BallTriangle } from "react-loader-spinner";
import {
  Container,
  CssBaseline,
  Grid,
  IconButton,
  ThemeProvider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import MailIcon from "@mui/icons-material/Mail";
import { theme } from "../../Theme";
import Header from "../../header";
import DataTable from "react-data-table-component";
import { artObjectConverter } from "../../data/art_object";
import CloseIcon from "@mui/icons-material/Close";

export default function AdminUserReservationView() {
  const db = getFirestore();
  const [reservations, setReservations] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const fetchReservations = async () => {
    const db = getFirestore();
    const snapshot = await getDocs(
      collection(db, "reservations").withConverter(reservationConverter)
    );
    const reservations = snapshot.docs
      .map((doc) => doc.data())
      .sort((r1, r2) => (r1.reservationDate > r2.reservationDate ? 1 : -1));
    setReservations(reservations);
    setLoaded(true);
  };

  const formatDate = (date) => {
    return date ? date.format("DD.MM.YYYY HH:mm") : "";
  };

  useEffect(() => {
    fetchReservations().catch(console.error);
  }, []);

  if (!loaded) {
    return (
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <BallTriangle color="#00BFFF" height={80} width={80} />
      </div>
    );
  }

  const removeSale = (reservation, data) => {
    console.log("remove fulfill of reservation for object " + reservation.id);
    //set unfulfilled
    setDoc(
      doc(db, "reservations", reservation.id),
      { fulfilled: false, confirmationDate: null },
      { merge: true }
    ).catch(console.error);

    //remove from buyer
    const filtered = data.buyerList.filter((resId) => resId === reservation.id);
    setDoc(
      doc(db, "objects", reservation.objectId),
      { buyerList: filtered },
      { merge: true }
    ).catch(console.error);

    //re-add to reservation
    const reservationList = (data.reservationList ?? []).concat(reservation.id);
    setDoc(
      doc(db, "objects", reservation.objectId),
      { reservationList: reservationList },
      { merge: true }
    ).catch(console.error);
  };

  const removeReservation = (reservation, data) => {
    console.log("delete reservation " + reservation.id);
    deleteDoc(doc(db, "reservations", reservation.id)).catch(console.error);
    const filtered = data.reservationList.filter(
      (resId) => resId === reservation.id
    );
    setDoc(
      doc(db, "objects", reservation.objectId),
      {
        reservationList: filtered,
        amountAvailable: data.amountAvailable + reservation.amount,
      },
      { merge: true }
    ).catch(console.error);
  };

  const handleCancelClick = async (reservation) => {
    const snapshot = await getDoc(
      doc(db, "objects", reservation.objectId).withConverter(artObjectConverter)
    );
    const data = snapshot.data();

    if (reservation.fulfilled) {
      removeSale(reservation, data);
    } else {
      removeReservation(reservation, data);
    }
    await fetchReservations();
  };

  const handleConfirmClick = async (reservation) => {
    console.log(reservation);
    const snapshot = await getDoc(
      doc(db, "objects", reservation.objectId).withConverter(artObjectConverter)
    );
    const data = snapshot.data();
    await setDoc(
      doc(db, "reservations", reservation.id.toString()),
      {
        fulfilled: true,
        confirmationDate: Timestamp.fromDate(new Date()),
      },
      { merge: true }
    );
    //remove from reservation & add to buyer
    const reservationList = data.reservationList.filter(
      (resId) => resId === reservation.id
    );
    const buyer = (data.buyerList ?? []).concat(reservation.id);
    setDoc(
      doc(db, "objects", reservation.objectId),
      { reservationList: reservationList, buyerList: buyer },
      { merge: true }
    ).catch(console.error);
    await fetchReservations();
  };

  const columns = [
    {
      name: "Objekt",
      selector: (reservation) => reservation.objectName,
      reorder: true,
      sortable: true,
      wrap: true,
    },
    {
      name: "User",
      selector: (reservation) => reservation.reservationUserName,
      reorder: true,
      sortable: true,
      wrap: true,
    },
    {
      name: "Mail",
      selector: (reservation) => reservation.reservationEmail,
      reorder: true,
      sortable: true,
      wrap: true,
    },
    {
      name: "Anzahl",
      selector: (reservation) => reservation.amount,
      reorder: true,
      sortable: true,
      wrap: true,
    },
    {
      name: "Reservierung",
      selector: (reservation) => formatDate(reservation.reservationDate),
      reorder: true,
      sortable: true,
      width: "13em",
    },
    {
      name: "Bestätigung",
      selector: (reservation) =>
        reservation.fulfilled && reservation.reservationDate
          ? formatDate(reservation.reservationDate)
          : "offen",
      reorder: true,
      sortable: true,
      width: "13em",
    },
    {
      name: "Action",
      width: "10em",
      cell: (reservation) => (
        <>
          <IconButton
            aria-label="mail"
            color="info"
            onClick={() =>
              (window.location = "mailto:" + reservation.reservationEmail)
            }
          >
            <MailIcon />
          </IconButton>
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => handleConfirmClick(reservation)}
          >
            <CheckIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            color="warning"
            onClick={() => handleCancelClick(reservation)}
          >
            {reservation.fulfilled ? <CloseIcon /> : <DeleteIcon />}
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <CssBaseline />
        <Header name={"Reservierungen Übersicht"} />
        <Grid
          container
          spacing={2}
          sx={{
            paddingTop: 4,
            paddingBottom: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid item>
            <DataTable
              columns={columns}
              data={reservations}
              highlightOnHover
              pagination
              pointerOnHover
              responsive
              striped
            />
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
