import * as React from "react";
import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Header from "../../header";
import CssBaseline from "@mui/material/CssBaseline";
import { Button, Divider, Grid, ThemeProvider } from "@mui/material";
import { theme } from "../../Theme";
import Typography from "@mui/material/Typography";
import { DateTimePicker } from "@mui/x-date-pickers";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { configConverter, ConfigData } from "../../data/config_data";
import dayjs from "dayjs";
import { toast } from "react-toastify";

export default function AdminAuktion() {
  const [endStill, setEndStill] = useState(dayjs());
  const [endLive, setEndLive] = useState(dayjs());
  const db = getFirestore();
  const fetchDates = async () => {
    const snapshot = await getDocs(
      collection(db, "config").withConverter(configConverter)
    );

    const data = snapshot.docs.map((doc) => doc.data());
    const still: ConfigData = data.find((config) => config.type === "still");
    const live: ConfigData = data.find((config) => config.type === "live");
    setEndLive(live.endingDate);
    setEndStill(still.endingDate);
  };

  useEffect(() => {
    fetchDates().catch(console.error);
  }, []);

  async function save(date, id) {
    console.log(date);
    await updateDoc(
      doc(db, "config", id),
      { endingDate: Timestamp.fromDate(date.toDate()) },
      { merge: true }
    );
    toast.info("Update erfolgreich");
  }

  async function saveEndingLive() {
    await save(endLive, "1");
  }

  async function saveEndingStill() {
    await save(endStill, "2");
  }

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <CssBaseline />
        <Header name={"Auktion verwalten"} />
        <Grid
          container
          spacing={2}
          sx={{
            paddingTop: 4,
            paddingBottom: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid item p={2} xs={12} sm={7}>
            <Grid
              container
              spacing={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item p={2} xs={12} width={"100%"}>
                <Typography variant="h4" gutterBottom>
                  Auktionssteuerung
                </Typography>
              </Grid>

              <Grid item p={2} xs={12} width={"100%"}>
                <Typography variant="h5" gutterBottom>
                  Kunstdrucke
                </Typography>
              </Grid>

              <Grid item p={2} xs={12} width={"100%"}>
                <Grid container>
                  <Grid item p={2} xs={12} sm={8} width={"100%"}>
                    <DateTimePicker
                      format={"DD.MM.YYYY HH:mm"}
                      ampm={false}
                      defaultValue={endStill}
                      value={endStill}
                      onChange={(newDate) => {
                        setEndStill(newDate);
                      }}
                      fullWidth
                      label={"Kunstdrucke Ende"}
                    />
                  </Grid>

                  <Grid item p={2} xs={12} sm={4} width={"100%"}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={saveEndingStill}
                      style={{ borderRadius: 20, background: "#F1B434" }}
                    >
                      Speichern
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item p={2} xs={12} width={"100%"}>
                <Typography variant="h5" gutterBottom>
                  Live Auktion
                </Typography>
              </Grid>

              <Grid item p={2} xs={12} width={"100%"}>
                <Grid container>
                  <Grid item p={2} xs={12} sm={8} width={"100%"}>
                    <DateTimePicker
                      format={"DD.MM.YYYY HH:mm"}
                      ampm={false}
                      value={endLive}
                      defaultValue={endLive}
                      onChange={(newDate) => {
                        setEndLive(newDate);
                      }}
                      fullWidth
                      label={"Live Auktion Ende"}
                    />
                  </Grid>

                  <Grid item p={2} xs={12} sm={4} width={"100%"}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={saveEndingLive}
                      style={{ borderRadius: 20, background: "#F1B434" }}
                    >
                      Speichern
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
