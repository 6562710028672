import React from "react";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { FormInputProps } from "./FormInputProps";
import { MenuItem } from "@mui/material";

export const FormInputSelect = ({
  name,
  control,
  label,
  required,
  options,
  handleChange,
}: FormInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          select
          defaultValue=""
          helperText={error ? error.message : null}
          size="small"
          error={!!error}
          onChange={(event) => {
            handleChange(event);
            onChange(event);
          }}
          value={value}
          fullWidth
          label={label}
          variant="outlined"
          required={required}
        >
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
};
