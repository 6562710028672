import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Container,
  CssBaseline,
  Grid,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import Header from "../../header";
import * as React from "react";
import { useEffect, useState } from "react";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { artObjectConverter } from "../../data/art_object";
import { BallTriangle } from "react-loader-spinner";
import { Breathing, Image } from "react-shimmer";
import { theme } from "../../Theme";

export interface IProps {
  stillerKauf: boolean;
}

export default function Auction(props) {
  const [objects, setObjects] = useState([]);
  // const [loading, setLoading] = useState(true)
  const fetchObjects = async () => {
    const db = getFirestore();
    const snapshot = await getDocs(
      collection(db, "objects").withConverter(artObjectConverter)
    );
    const data = snapshot.docs.map((doc) => doc.data());
    setObjects(data);
  };

  const currencyFormat = (num) => {
    return (
      "€" +
      parseInt(num, 10)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  };

  useEffect(() => {
    fetchObjects().catch(console.error);
  }, []);

  if (objects.length === 0) {
    return (
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <BallTriangle color="#00BFFF" height={80} width={80} />
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <CssBaseline />
        <Box paddingTop={5} paddingBottom={4}>
          <Container>
            <Header
              name={
                "Kunstobjekte - " +
                (props.stillerKauf ? "Kunstdrucke" : "Live Auktion")
              }
            />
            <Grid
              container
              spacing={4}
              alignItems="center"
              direction="row"
              justifyContent="center"
            >
              {objects &&
                objects
                  .sort((a, b) => {
                    return a.id - b.id;
                  })
                  .filter((object) => !object.deleted)
                  .map((object) =>
                    object.still === props.stillerKauf ? (
                      <Grid item key={object.id} align="center" xs={6} sm={3}>
                        <Card
                          sx={{
                            height: 270,
                            width: "100%",
                            maxWidth: 200,
                            borderRadius: 5,
                          }}
                        >
                          <CardActionArea
                            to={`/objects/${object.id}`}
                            component={Link}
                          >
                            <div style={{ height: 140 }}>
                              <Image
                                NativeImgProps={{ height: 140 }}
                                src={object.image1}
                                fallback={
                                  <Breathing width="100%" height="100%" />
                                }
                              />
                            </div>
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="body2"
                                component="div"
                                style={{ fontStyle: "italic" }}
                              >
                                {object.artist}: {object.name}
                              </Typography>
                              {props.stillerKauf ? (
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {currencyFormat(object.buyingPrice)}
                                </Typography>
                              ) : (
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  Start: {currencyFormat(object.startingPrice)}
                                </Typography>
                              )}
                              {props.stillerKauf ? (
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {object.amountAvailable ?? "1"}/
                                  {object.amountTotal ?? "1"} verfügbar
                                </Typography>
                              ) : (
                                <></>
                              )}
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    ) : null
                  )}
            </Grid>
          </Container>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
