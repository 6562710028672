import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  CssBaseline,
  Divider,
  Grid,
  IconButton,
  ThemeProvider,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import { AdminChatUserListView } from "./chat/AdminChatUserListView";
import { userConverter } from "../../data/user";
import TextField from "@mui/material/TextField";
import { theme } from "../../Theme";
import Header from "../../header";
import Typography from "@mui/material/Typography";
import { messageConverter } from "../../data/message";

export default function AdminChatView() {
  const db = getFirestore();
  const auth = getAuth();
  const navigate = useNavigate();

  const [receiverData, setReceiverData] = useState(null);
  const [chatMessage, setChatMessage] = useState("");
  const [users, setUsers] = useState([]);
  const [allMessages, setAllMessages] = useState([]);

  const [userId, setUserId] = useState(null);

  onAuthStateChanged(auth, (user) => {
    if (user && user.uid && user.uid !== userId) {
      if (user.uid) {
        console.log("user logged in");
        setUserId(user.uid);
      } else {
        console.log("user logged out");
        setUserId(null);
      }
    }
  });

  useEffect(() => {
    return onSnapshot(collection(db, "support"), (snapshot) => {
      const loadUserData = async (snapshot) => {
        return Promise.all(
          snapshot.docs
            .map((doc) => doc.data())
            .map(async (request) => {
              return (
                await getDoc(
                  doc(db, "users", request.userId).withConverter(userConverter)
                )
              ).data();
            })
        ).then((u) => {
          setUsers(u);
        });
      };
      return loadUserData(snapshot);
    });
  }, [db]);

  useEffect(() => {
    if (receiverData) {
      return onSnapshot(
        query(
          collection(
            db,
            "support",
            receiverData.userId,
            "messages"
          ).withConverter(messageConverter),
          orderBy("timestamp")
        ),
        (snapshot) => {
          setAllMessages(
            snapshot.docs.map((doc) => {
              return doc.data();
            })
          );
          scrollToBottom();
        }
      );
    }
  }, [receiverData, db]);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    console.log("scrollToBottom");
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [receiverData]);

  const sendMessage = async (event) => {
    event.preventDefault();
    try {
      if (auth.currentUser && receiverData) {
        const messages = (
          await getDocs(
            collection(db, "support", receiverData.userId, "messages")
          )
        ).docs.map((message) => message.data());
        const nextMessageId = (messages.length ?? 0).toString();
        await setDoc(
          doc(db, "support", receiverData.userId, "messages", nextMessageId),
          {
            userId: userId,
            timestamp: Timestamp.fromDate(new Date()),
            message: chatMessage,
            read: false,
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
    setChatMessage("");
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <CssBaseline />
        <Header name={"Nachrichten Übersicht"} />
        <Grid
          container
          spacing={2}
          sx={{
            paddingTop: 4,
            paddingBottom: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={root}>
            <Paper style={left}>
              <div
                style={{
                  display: "flex",
                  padding: 10,
                  justifyContent: "space-between",
                }}
              ></div>
              <Divider />
              <Typography
                variant="h5"
                gutterBottom
                style={{ padding: 5 }}
                color="text.secondary"
                align="center"
              >
                Anfragen
              </Typography>
              <div style={{ overflowY: "scroll" }}>
                <AdminChatUserListView
                  users={users}
                  setReceiverData={setReceiverData}
                  navigate={navigate}
                  currentUserId={userId}
                />
              </div>
            </Paper>

            <Paper style={right}>
              <h4 style={{ margin: 2, padding: 10 }}>
                {receiverData ? receiverData.username : ""}{" "}
              </h4>

              <Divider />
              <div style={messagesDiv}>
                {/* messages area */}

                {allMessages &&
                  allMessages.map((message) => {
                    return (
                      <div
                        key={message.id}
                        style={{
                          margin: 2,
                          display: "flex",
                          flexDirection:
                            receiverData.userId !== message.userId
                              ? "row-reverse"
                              : "row",
                        }}
                      >
                        <span
                          style={{
                            backgroundColor:
                              receiverData.userId !== message.userId
                                ? "#E8AC2E"
                                : "#E8AC2E1A",
                            padding: 10,
                            borderTopLeftRadius:
                              receiverData.userId !== message.userId ? 10 : 0,
                            borderTopRightRadius:
                              receiverData.userId !== message.userId ? 0 : 10,
                            borderBottomLeftRadius: 10,
                            borderBottomRightRadius: 10,
                            maxWidth: 400,
                            fontSize: 15,
                            textAlign:
                              receiverData.userId !== message.userId
                                ? "right"
                                : "left",
                          }}
                        >
                          {message.message}
                        </span>
                      </div>
                    );
                  })}
                <div ref={messagesEndRef} />
              </div>

              <form onSubmit={sendMessage}>
                <div style={{ width: "100%", display: "flex", flex: 0.08 }}>
                  <TextField
                    value={chatMessage}
                    onChange={(e) => setChatMessage(e.target.value)}
                    style={input}
                    onSubmit={sendMessage}
                    type="text"
                    placeholder="Nachricht eingeben..."
                  />
                  <IconButton type="submit">
                    <SendIcon style={{ margin: 10 }} />
                  </IconButton>
                </div>
              </form>
            </Paper>
          </div>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}

const root = {
  display: "flex",
  flexDirection: "row",
  flex: 1,
  width: "100%",
};

const left = {
  display: "flex",
  flex: 0.1,
  height: "75vh",
  margin: 10,
  flexDirection: "column",
};

const right = {
  display: "flex",
  flex: 0.9,
  height: "75vh",
  margin: 10,
  flexDirection: "column",
};

const input = {
  flex: 1,
  outline: "none",
  borderRadius: 5,
  border: "none",
};

const messagesDiv = {
  backgroundColor: "#FBEEE6",
  padding: 5,
  display: "flex",
  flexDirection: "column",
  flex: 1, // maxHeight: 600,
  overflowY: "scroll",
};
