import {
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { artObjectConverter } from "../../data/art_object";
import {
  ArtObjectReservation,
  reservationConverter,
} from "../../data/art_object_reservation";
import { v4 as uuidv4 } from "uuid";

export const postBiddingToFirestore = async (
  db,
  objectId,
  userId,
  userEmail,
  userName,
  setDataLoaded,
  bidding
) => {
  console.log(objectId);
  console.log(userId);
  console.log(userEmail);
  console.log(userName);
  console.log(bidding);

  const biddings = (
    await getDocs(collection(db, "objects", objectId, "biddings"))
  ).docs.map((bid) => bid.data());
  const userBiddings = (
    await getDocs(collection(db, "users", userId, "biddings"))
  ).docs.map((bid) => bid.data());

  const nextBiddingId = (biddings.length ?? 0).toString();
  const nextUserBiddingId = (userBiddings.length ?? 0).toString();

  console.log(
    "write " +
      nextBiddingId +
      " bidding of " +
      bidding +
      " to art auction " +
      objectId
  );
  await setDoc(doc(db, "objects", objectId, "biddings", nextBiddingId), {
    user: userId,
    bidding: bidding,
    biddingDate: Timestamp.fromDate(new Date()),
    bidderEmail: userEmail,
    bidderName: userName,
  });

  console.log(
    "write " +
      nextUserBiddingId +
      " bidding of " +
      bidding +
      " to user " +
      userId
  );
  await setDoc(doc(db, "users", userId, "biddings", nextUserBiddingId), {
    artObjectId: objectId,
    bidding: bidding,
    biddingDate: Timestamp.fromDate(new Date()),
  });

  const docSnap = await getDoc(
    doc(db, "objects", objectId).withConverter(artObjectConverter)
  );
  const fetchedObject = docSnap.data();

  if (bidding > fetchedObject.highestBidding) {
    await updateDoc(doc(db, "objects", objectId), {
      highestBidding: bidding,
      highestBidder: userId,
      highestBidderEmail: userEmail,
      highestBidderName: userName,
    });
  }

  setDataLoaded(false);
};

export const postReservationToFirestore = async (
  db,
  objectId,
  objectName,
  userId,
  userEmail,
  userName,
  amount,
  setDataLoaded
) => {
  console.log(objectId);
  console.log(objectName);
  console.log(userId);
  console.log(userEmail);
  console.log(userName);
  console.log(amount);

  const reservationId = uuidv4();

  console.log(
    "write " + reservationId + " reservation of art object " + objectId
  );

  const reservation = new ArtObjectReservation(
    reservationId,
    userId,
    objectId,
    objectName,
    new Date(),
    null,
    userEmail,
    userName,
    false,
    amount
  );

  await setDoc(
    doc(db, "reservations", reservationId).withConverter(reservationConverter),
    reservation
  );

  const docSnap = await getDoc(
    doc(db, "objects", objectId).withConverter(artObjectConverter)
  );
  const fetchedObject = docSnap.data();
  const reservationList = (fetchedObject.reservationList ?? []).concat(
    reservationId
  );
  await updateDoc(doc(db, "objects", objectId), {
    reservationList: reservationList,
    amountAvailable: (fetchedObject.amountAvailable ?? 1) - amount,
  });
  setDataLoaded(false);
};
