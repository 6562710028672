import * as React from "react";
import Container from "@mui/material/Container";
import Header from "../../header";
import CssBaseline from "@mui/material/CssBaseline";
import { Grid, ThemeProvider, Typography } from "@mui/material";
import { LinkCard } from "../../common/LinkCard";
import { theme } from "../../Theme";
import { useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export default function AdminSelect() {
  const auth = getAuth();
  const [userId, setUserId] = useState(null);

  onAuthStateChanged(auth, (user) => {
    if (user && user.uid && user.uid !== userId) {
      if (user.uid) {
        console.log("user logged in");
        setUserId(user.uid);
      } else {
        console.log("user logged out");
        setUserId(null);
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <CssBaseline />
        <Header />
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={{ xs: 2, sm: 4 }}
          sx={{ padding: 4 }}
        >
          <Grid item xs={12}>
            <Typography
              align="center"
              variant="h6"
              style={{ textTransform: "uppercase" }}
            >
              Admin Center
            </Typography>
          </Grid>

          <Grid item align="center" xs={6}>
            {LinkCard("Objekte verwalten", "/admin/objects")}
          </Grid>

          <Grid item align="center" xs={6}>
            {LinkCard("Reservierungen verwalten", "/admin/reservations")}
          </Grid>

          <Grid item align="center" xs={6}>
            {LinkCard("User verwalten", "/admin/users")}
          </Grid>

          <Grid item align="center" xs={6}>
            {LinkCard("Auktion verwalten", "/admin/auktion")}
          </Grid>

          <Grid item align="center" xs={6}>
            {LinkCard("Support Postfach", "/admin/support/" + userId)}
          </Grid>

          <Grid item align="center" xs={6}>
            {LinkCard("Manuelles Gebot", "/admin/bidding")}
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
